import React from "react";
import {
  PortfolioCategoriesItem,
  PortfolioCategoriesItemInner,
  PortfolioCategoriesLinkBorder,
  StyledPortfolioCategoriesList,
} from "./styles";

const PortfolioCategoriesList = ({ portfolioTechnologiesList }) => {
  const isMain = (item) => {
    return (
      item?.id === portfolioTechnologiesList?.attributes?.mainCategory?.data?.id
    );
  };

  return (
    <StyledPortfolioCategoriesList>
      {portfolioTechnologiesList?.attributes?.categories?.data?.map((item) => {
        return (
          <PortfolioCategoriesItem
            key={item?.id}
            className={isMain(item) ? "mainWrapper" : undefined}
          >
            <PortfolioCategoriesItemInner
              className={isMain(item) ? "main" : undefined}
            >
              {isMain(item) && <PortfolioCategoriesLinkBorder />}
              {item?.attributes?.preview?.title}
            </PortfolioCategoriesItemInner>
          </PortfolioCategoriesItem>
        );
      })}
    </StyledPortfolioCategoriesList>
  );
};

export default PortfolioCategoriesList;
