import React from "react";
import { Slider } from "../slider";
import { PortfolioTechnologiesItem } from "../../styles/portfolio";
import Cube from "../../assets/icons/Cube";
import Typography from "@mui/material/Typography";

const PortfolioTechnologiesListSlider = ({ portfolioTechnologiesList }) => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      portalIdNext="technologies-next-arrow"
      portalIdPrev="technologies-prev-arrow"
      width="100%"
      margin="0px"
      wrapperProps={{
        marginBottom: "68px",
      }}
    >
      {portfolioTechnologiesList?.technologies?.map((item) => {
        return (
          <PortfolioTechnologiesItem key={item.id}>
            <Typography variant="h4" className="listItemTitle">
              {item.title}
              <Cube />
            </Typography>
            <div>{item.text}</div>
          </PortfolioTechnologiesItem>
        );
      })}
    </Slider>
  );
};

export default PortfolioTechnologiesListSlider;
