import React from "react";
import Cube from "../../assets/icons/Cube";
import { PortfolioOutcomeItem } from "../../styles/portfolio";
import Typography from "@mui/material/Typography";

const PortfolioOutcomeList = ({ portfolioOutcomeList }) => {
  return (
    <>
      {portfolioOutcomeList?.theOutcomeItems?.map((item) => {
        return (
          <PortfolioOutcomeItem
            key={item.id}
            className={
              portfolioOutcomeList?.theOutcomeItems?.length <= 2 ? "single" : ""
            }
          >
            <div className="listItemTitle">
              {item.title}
              <Cube />
            </div>
            {item.subTitle && (
              <Typography variant="h4" className="listItemSubTitle">
                {item.subTitle}
              </Typography>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          </PortfolioOutcomeItem>
        );
      })}
    </>
  );
};

export default PortfolioOutcomeList;
