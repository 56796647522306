import React from "react";
import Cube from "../../assets/icons/Cube";
import { ChallengesItem } from "../../styles/portfolio";

const Challenges = ({ challenges }) => {
  return (
    <>
      {challenges?.items?.map((item) => {
        return (
            <ChallengesItem key={item.id} className={challenges?.items?.length < 2 ? "single" : ""}>
              <div className="listItemTitle">
                {item.title}
                <Cube />
              </div>
              <div>{item.text}</div>
            </ChallengesItem>
        );
      })}
    </>
  );
};

export default Challenges;
