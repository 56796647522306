import React from "react";
import { Slider } from "../slider";
import { ChallengesItem } from "../../styles/portfolio";
import Cube from "../../assets/icons/Cube";

const ChallengesSlider = ({ challenges }) => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      portalIdNext="challenge-next-arrow"
      portalIdPrev="challenge-prev-arrow"
      width="100%"
      margin="0px"
      wrapperProps={{
        marginBottom: "68px",
      }}
    >
      {challenges?.items?.map((item) => {
        return (
          <ChallengesItem key={item.id}>
            <div className="listItemTitle">
              {item.title}
              <Cube />
            </div>
            <div>{item.text}</div>
          </ChallengesItem>
        );
      })}
    </Slider>
  );
};

export default ChallengesSlider;
