import React from "react";
import Cube from "../../assets/icons/Cube";
import { PortfolioTechnologiesItem } from "../../styles/portfolio";
import Typography from "@mui/material/Typography";

const PortfolioTechnologiesList = ({ portfolioTechnologiesList }) => {
  return (
    <>
      {portfolioTechnologiesList?.technologies?.map((item) => {
        return (
          <PortfolioTechnologiesItem key={item.id}>
            <Typography variant="h4" className="listItemTitle">
              {item.title}
              <Cube />
            </Typography>
            <div>{item.text}</div>
          </PortfolioTechnologiesItem>
        );
      })}
    </>
  );
};

export default PortfolioTechnologiesList;
