import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Seo from "../components/seo";
import { HOST_IMAGE_URL } from "../constants";
import malevichAnimation from "../assets/animations/malevichAnimation.json";
import {
  AnimationImage,
  PortfolioBackgroundInner,
  PortfolioCategory,
  PortfolioCategoryBanner,
  PortfolioCategoryDescription,
  PortfolioCategoryInfo,
  PortfolioCategoryTop,
  PortfolioChallenge,
  PortfolioChallengeWrapper,
  PortfolioClient,
  PortfolioClientCase,
  PortfolioClientDescription,
  PortfolioClientDescriptionCases,
  PortfolioClientDescriptionContent,
  PortfolioClientImage,
  PortfolioClientInfo,
  PortfolioClientLink,
  PortfolioDoubleBlock,
  PortfolioImage,
  PortfolioOutcome,
  PortfolioOutcomeWrapper,
  PortfolioServices,
  PortfolioServicesItem,
  PortfolioServicesWrapper,
  PortfolioSolution,
  PortfolioSolutionDescription,
  PortfolioSolutionImage,
  PortfolioSolutionInfo,
  PortfolioStack,
  PortfolioStackItem,
  PortfolioStackWrapper,
  PortfolioTechnologies,
  PortfolioTechnologiesWrapper,
  BackButton,
  BackButtonImage,
} from "../styles/portfolio";
import Typography from "@mui/material/Typography";
import PortfolioCategoriesList from "../components/portfolioCategoriesList";
import ArrowHeading from "../components/arrowHeading";
import palette from "../theme/palette";
import Challenges from "../components/challenges/Challenges";
import ChallengesSlider from "../components/challenges/ChallengesSlider";
import {
  SliderAdaptiveContainer,
  SliderBackground,
} from "../components/slider/styles";
import PortfolioTechnologiesListSlider from "../components/portfolioTechnologiesList/PortfolioTechnologiesListSlider";
import PortfolioTechnologiesList from "../components/portfolioTechnologiesList/PortfolioTechnologiesList";
import PortfolioOutcomeList from "../components/portfolioOutcomeList/PortfolioOutcomeList";
import PortfolioOutcomeListSlider from "../components/portfolioOutcomeList/PortfolioOutcomeListSlider";
import Container from "../components/container";
import { motion } from "framer-motion";
import LogoBackground from "../assets/icons/LogoBackground";
import PortfolioSolutionImg from "../assets/icons/PortfolioSolutionImg";
import LottieIcon from "../components/lottieIcons";
import { useBreakpointValue } from "../hooks/use-breakpoint-value";
import ArrowRight from "../assets/icons/ArrowRight";

const Case = ({
  data: {
    strapi: {
      case: { data },
    },
  },
}) => {
  const isMobile = useBreakpointValue(["smallMobile", "mobile"]);

  const logoAnimation = {
    animationData: malevichAnimation,
    loop: 0,
    autoplay: true,
  };

  const outcomeSizeSection = (element) => {
    if (element.length === 2) {
      return "twoSections";
    } else if (element.length === 1) {
      return "oneSection";
    } else {
      return undefined;
    }
  };

  return (
    <Layout>
      <Seo
        title={data?.attributes?.seo?.metaTitle}
        description={data?.attributes?.seo?.metaDescription}
        image={data?.attributes?.seo?.metaImage?.data?.attributes?.url}
      />
      <Container direction="column">
        {/* PORTFOLIO CATEGORY */}
        <PortfolioCategory>
          <PortfolioBackgroundInner>
            <LogoBackground />
          </PortfolioBackgroundInner>
          <PortfolioCategoryTop>
            <PortfolioCategoryInfo>
              <BackButton to='/cases'>
                <BackButtonImage>
                  <ArrowRight />
                </BackButtonImage>
                <Typography variant="body2" fontWeight="500">Back</Typography>
              </BackButton>
              <Typography variant="h1" fontWeight="600">
                {data.attributes.title}
              </Typography>
              <PortfolioCategoryDescription
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: data.attributes.description,
                }}
              />
            </PortfolioCategoryInfo>
            <PortfolioCategoriesList portfolioTechnologiesList={data} />
          </PortfolioCategoryTop>
          <PortfolioCategoryBanner>
            <img
              src={`${
                HOST_IMAGE_URL +
                data?.attributes?.mainImage?.data?.attributes?.url
              }`}
              alt={
                data?.attributes?.mainImage?.data?.attributes?.alternativeText
              }
              title={data?.attributes?.mainImage?.data?.attributes?.caption}
            />
          </PortfolioCategoryBanner>
        </PortfolioCategory>

        {/* PORTFOLIO CLIENT */}
        {data?.attributes?.elements?.map((element) => {
          if (element?.__typename === "strapi_ComponentCaseTheClient") {
            return (
              <PortfolioClient key={element.id}>
                <PortfolioClientInfo>
                  <ArrowHeading color={palette.amethystSmoke}>
                    The client
                  </ArrowHeading>
                  <PortfolioClientDescription>
                    <PortfolioClientDescriptionContent
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: element?.client?.data?.attributes?.title,
                      }}
                    />
                    <PortfolioClientDescriptionCases>
                      {element?.client?.data?.attributes?.url && (
                        <PortfolioClientLink
                          to={element?.client?.data?.attributes?.url}
                          target="_blank"
                        >
                          {element?.client?.data?.attributes?.urlName}
                        </PortfolioClientLink>
                      )}
                      <PortfolioClientCase>
                        Industry - {element?.client?.data?.attributes?.industry}
                      </PortfolioClientCase>
                      <PortfolioClientCase>
                        Location - {element?.client?.data?.attributes?.location}
                      </PortfolioClientCase>
                    </PortfolioClientDescriptionCases>
                  </PortfolioClientDescription>
                </PortfolioClientInfo>
                <PortfolioClientImage>
                  <img
                    src={`${
                      HOST_IMAGE_URL +
                      element?.client?.data?.attributes?.image?.data?.attributes
                        ?.url
                    }`}
                    alt={
                      element?.client?.data?.attributes?.image?.data?.attributes
                        ?.alternativeText
                    }
                    title={
                      element?.client?.data?.attributes?.image?.data?.attributes
                        ?.caption
                    }
                  />
                </PortfolioClientImage>
              </PortfolioClient>
            );
          }
          return null;
        })}

        <PortfolioDoubleBlock>
          {/* PORTFOLIO SERVICES */}
          {data?.attributes?.elements?.map((element) => {
            if (element?.__typename === "strapi_ComponentCaseServices") {
              return (
                <PortfolioServices key={element.id}>
                  <ArrowHeading color={palette.amethystSmoke} smallArrow>
                    Services
                  </ArrowHeading>
                  <PortfolioServicesWrapper>
                    {element?.services?.map((service) => {
                      return (
                        <PortfolioServicesItem key={service.id}>
                          {service?.title}
                        </PortfolioServicesItem>
                      );
                    })}
                  </PortfolioServicesWrapper>
                </PortfolioServices>
              );
            }
            return null;
          })}

          {/* PORTFOLIO STACK */}
          <PortfolioStack>
            <div>
              <ArrowHeading color={palette.amethystSmoke} smallArrow>
                Technology Stack
              </ArrowHeading>
              <PortfolioStackWrapper>
                {data?.attributes?.stackTechnologies?.data?.map((item) => {
                  return (
                    <PortfolioStackItem key={item.id}>
                      <img
                        src={`${
                          HOST_IMAGE_URL +
                          item?.attributes?.icon?.data?.attributes?.url
                        }`}
                        alt={
                          item?.attributes?.icon?.data?.attributes
                            ?.alternativeText
                        }
                        title={
                          item?.attributes?.icon?.data?.attributes?.caption
                        }
                      />
                    </PortfolioStackItem>
                  );
                })}
              </PortfolioStackWrapper>
            </div>
            {data?.attributes?.elements?.map((element) => {
              if (
                element?.__typename === "strapi_ComponentCaseStackTechnology" &&
                element?.isShowMalevichImage === true
              ) {
                return (
                  <AnimationImage key={element.id}>
                    <LottieIcon
                      options={logoAnimation}
                      replayInView
                      disableOnHover
                    />
                  </AnimationImage>
                );
              }
              return null;
            })}
          </PortfolioStack>
        </PortfolioDoubleBlock>

        {/* PORTFOLIO CHALLENGE */}
        {data?.attributes?.elements?.map((element) => {
          if (element?.__typename === "strapi_ComponentCaseTheChallenge") {
            return (
              <PortfolioChallenge key={element.id}>
                <Typography variant="h2" color={palette.amethystSmoke}>
                  The Challenge
                </Typography>
                <PortfolioChallengeWrapper>
                  {isMobile ? (
                    <>
                      {element?.items?.length < 2 ? (
                        <Challenges challenges={element} />
                      ) : (
                        <SliderAdaptiveContainer>
                          <ChallengesSlider challenges={element} />
                          <SliderBackground />
                        </SliderAdaptiveContainer>
                      )}
                    </>
                  ) : (
                    <Challenges challenges={element} />
                  )}
                  {element?.items?.length < 2 &&
                  element?.isShowMalevichImage === true ? (
                    <AnimationImage>
                      <LottieIcon
                        options={logoAnimation}
                        replayInView
                        disableOnHover
                      />
                    </AnimationImage>
                  ) : (
                    ""
                  )}
                </PortfolioChallengeWrapper>
              </PortfolioChallenge>
            );
          }
          return null;
        })}

        {/* PORTFOLIO SOLUTION */}
        {data?.attributes?.elements?.map((element) => {
          if (element?.__typename === "strapi_ComponentCaseTheSolution") {
            return (
              <PortfolioSolution key={element.id}>
                <PortfolioSolutionInfo>
                  <motion.div
                    initial={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <motion.span
                      className="target"
                      initial={{
                        backgroundPositionX: "-500px",
                        backgroundSize: "125%",
                      }}
                      whileInView={{ backgroundPositionX: "0px" }}
                      transition={{ type: "tween", duration: 3.5 }}
                    >
                      The Solution
                    </motion.span>
                    <span className="copy">The Solution</span>
                  </motion.div>
                  <PortfolioSolutionDescription
                    dangerouslySetInnerHTML={{
                      __html: element?.text,
                    }}
                  />
                </PortfolioSolutionInfo>
                <PortfolioSolutionImage>
                  <PortfolioSolutionImg />
                </PortfolioSolutionImage>
              </PortfolioSolution>
            );
          }
          return null;
        })}

        {/* PORTFOLIO TECHNOLOGIES */}
        {data?.attributes?.elements?.map((element) => {
          if (element?.__typename === "strapi_ComponentCaseTechnologies") {
            return (
              <PortfolioTechnologies key={element.id}>
                <Typography variant="h4" component="h3">
                  Technologies:
                </Typography>
                <PortfolioTechnologiesWrapper>
                  {isMobile ? (
                    <SliderAdaptiveContainer>
                      <PortfolioTechnologiesListSlider
                        portfolioTechnologiesList={element}
                      />
                      <SliderBackground />
                    </SliderAdaptiveContainer>
                  ) : (
                    <PortfolioTechnologiesList
                      portfolioTechnologiesList={element}
                    />
                  )}
                </PortfolioTechnologiesWrapper>
              </PortfolioTechnologies>
            );
          }
          return null;
        })}

        {/* PORTFOLIO IMAGES */}
        {data?.attributes?.elements?.map((element) => {
          if (element?.__typename === "strapi_ComponentCaseGallery") {
            const imageUrl = element?.media?.data?.flatMap((item) => {
              return item?.attributes?.url;
            });

            const imageName = element?.media?.data?.flatMap((item) => {
              return item?.attributes?.alternativeText;
            });

            const imageTitle = element?.media?.data?.flatMap((item) => {
              return item?.attributes?.caption;
            });

            return (
              <PortfolioImage key={element.id}>
                <img
                  src={`${HOST_IMAGE_URL + imageUrl}`}
                  alt={imageName}
                  title={imageTitle}
                />
              </PortfolioImage>
            );
          }
          return null;
        })}

        {/* PORTFOLIO OUTCOME */}
        {data?.attributes?.elements?.map((element) => {
          if (element?.__typename === "strapi_ComponentCaseTheOutcome") {
            return (
              <PortfolioOutcome key={element.id}>
                <ArrowHeading vertical color={palette.amethystSmoke}>
                  The Outcome
                </ArrowHeading>
                <PortfolioOutcomeWrapper
                  className={outcomeSizeSection(element?.theOutcomeItems)}
                >
                  {isMobile ? (
                    <>
                      {element?.theOutcomeItems?.length < 2 ? (
                        <PortfolioOutcomeList portfolioOutcomeList={element} />
                      ) : (
                        <SliderAdaptiveContainer className="outcome">
                          <PortfolioOutcomeListSlider
                            portfolioOutcomeList={element}
                          />
                          <SliderBackground />
                        </SliderAdaptiveContainer>
                      )}
                    </>
                  ) : (
                    <PortfolioOutcomeList portfolioOutcomeList={element} />
                  )}
                </PortfolioOutcomeWrapper>
              </PortfolioOutcome>
            );
          }
          return null;
        })}
      </Container>
    </Layout>
  );
};

export default Case;

export const query = graphql`
  query caseQuery($id: ID!) {
    strapi {
      case(id: $id) {
        data {
          id
          attributes {
            seo {
              ...seo
            }
            slug
            title
            description
            mainCategory {
              data {
                attributes {
                  title
                }
                id
              }
            }
            categories {
              data {
                id
                attributes {
                  preview {
                    title
                  }
                }
              }
            }
            mainImage {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }

            stackTechnologies {
              data {
                id
                attributes {
                  icon {
                    data {
                      attributes {
                        url
                        alternativeText
                        caption
                      }
                    }
                  }
                }
              }
            }

            elements {
              __typename

              ... on strapi_ComponentCaseTheClient {
                id
                client {
                  data {
                    id
                    attributes {
                      title
                      url
                      urlName
                      industry
                      location
                      image {
                        data {
                          attributes {
                            url
                            alternativeText
                            caption
                          }
                        }
                      }
                    }
                  }
                }
              }

              ... on strapi_ComponentCaseServices {
                id
                services(pagination: { limit: 100 }) {
                  id
                  title
                }
              }

              ... on strapi_ComponentCaseStackTechnology {
                id
                isShowMalevichImage
              }

              ... on strapi_ComponentCaseTheChallenge {
                id
                isShowMalevichImage
                items {
                  id
                  title
                  text
                }
              }

              ... on strapi_ComponentCaseTheSolution {
                id
                text
              }

              ... on strapi_ComponentCaseTechnologies {
                id
                technologies {
                  id
                  text
                  title
                }
              }

              ... on strapi_ComponentCaseGallery {
                id
                media {
                  data {
                    attributes {
                      url
                      alternativeText
                      caption
                    }
                  }
                }
              }

              ... on strapi_ComponentCaseTheOutcome {
                id
                theOutcomeItems {
                  id
                  title
                  subTitle
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
