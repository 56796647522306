import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../theme";
import palette from "../theme/palette";
import { Link } from "gatsby";
import Button from "@mui/material/Button";

// PORTFOLIO CATEGORY
export const PortfolioCategory = styled(Box)({
  position: "relative",
  paddingTop: "120px",

  [theme.breakpoints.down("lg")]: {
    paddingTop: "40px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "calc(100% + 32px)",
    marginLeft: "-16px",
    padding: "40px 16px 0",
  },
});

export const PortfolioBackgroundInner = styled(Box)({
  position: "absolute",
  top: "-90px",
  left: 0,
  zIndex: -1,

  "& svg": {
    width: "100%",
    height: "100%",
  },

  [theme.breakpoints.down("xl")]: {
    top: "-65px",
  },

  [theme.breakpoints.down("lg")]: {
    top: "-45px",
  },

  [theme.breakpoints.down("md")]: {
    top: "-22px",
  },
});

export const PortfolioCategoryTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "normal",
  },
});

export const PortfolioCategoryInfo = styled(Box)({
  maxWidth: "640px",
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    "& h1": {
      color: palette.purpleBlue,
    },
  },
});

export const BackButton = styled(Link)({
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
});

export const BackButtonImage = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "36px",
  height: "36px",
  marginRight: "12px",
  backgroundColor: palette.primary,

  "& svg": {
    transform: "rotate(180deg)",
    "& path": {
      fill: palette.secondary,
    },
  },
});

export const PortfolioCategoryDescription = styled(Typography)({
  marginTop: "26px",

  [theme.breakpoints.down("lg")]: {
    margin: "8px 0 16px 0",
  },
});

export const PortfolioCategoryBanner = styled(Box)({
  width: "100%",
  marginTop: "120px",

  "& img": {
    width: "100%",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "44px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "calc(100% + 32px)",
    marginLeft: "-16px",
  },
});
// END OF PORTFOLIO CATEGORY

// PORTFOLIO CLIENT
export const PortfolioClient = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "120px",

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    justifyContent: "normal",
    marginTop: "60px",
  },
});

export const PortfolioClientInfo = styled(Box)({
  marginTop: "44px",

  "& svg": {
    width: "80px",
  },

  [theme.breakpoints.down("xl")]: {
    marginTop: 0,
  },
});

export const PortfolioClientImage = styled(Box)({
  marginLeft: "30px",

  "& img": {
    maxWidth: "625px",
    width: "100%",
  },

  [theme.breakpoints.down("xl")]: {
    marginLeft: 0,
  },

  [theme.breakpoints.down("sm")]: {
    width: "calc(100% + 32px)",
    marginLeft: "-16px",
  },
});

export const PortfolioClientDescription = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "44px",

  [theme.breakpoints.down("xl")]: {
    marginTop: "32px",
  },

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    justifyContent: "normal",
  },
});

export const PortfolioClientDescriptionContent = styled(Typography)({
  width: "225px",
  marginRight: "75px",
  letterSpacing: "0.15px",

  [theme.breakpoints.down("xl")]: {
    maxWidth: "343px",
    width: "100%",
    marginBottom: "16px",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },
});

export const PortfolioClientDescriptionCases = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginTop: "84px",

  [theme.breakpoints.down("xl")]: {
    marginTop: 0,
  },
});

export const PortfolioClientLink = styled(Link)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "16px",
  padding: "16px",
  color: palette.secondary,
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "30px",
  textDecoration: "underline",
  background: palette.purpleBlue,

  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textDecoration: "none",
  },
});

export const PortfolioClientCase = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "16px",
  padding: "16px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "22px",
  border: `1px solid ${palette.amethystSmoke}`,

  "&:last-child": {
    marginBottom: 0,
  },

  [theme.breakpoints.down("xl")]: {
    "&:last-child": {
      marginBottom: "16px",
    },
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
  },
});
// END OF PORTFOLIO CLIENT

export const PortfolioDoubleBlock = styled(Box)({
  display: "flex",
  margin: "120px 0 44px",

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
  },

  [theme.breakpoints.down("lg")]: {
    margin: "60px 0 0",
  },
});

// PORTFOLIO SERVICES
export const PortfolioServices = styled(Box)({
  width: "50%",
  marginRight: "30px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    marginRight: 0,
    marginBottom: "44px",
  },
});

export const PortfolioServicesWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  flexWrap: "wrap",
  marginTop: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "calc(100% + 26px)",
    marginLeft: "-13px",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
});

export const PortfolioServicesItem = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "26px 26px 0 0",
  padding: "16px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "22px",
  border: `1px solid ${palette.amethystSmoke}`,

  [theme.breakpoints.down("xl")]: {
    margin: "26px 13px 0",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "16px",
    padding: "8px 12px",
    fontSize: "14px",
    fontWeight: 500,
  },
});
// END OF PORTFOLIO SERVICES

// PORTFOLIO STACK
export const PortfolioStack = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "50%",
  marginLeft: "30px",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
    marginLeft: 0,
  },
});

export const PortfolioStackWrapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "10px",

  [theme.breakpoints.down("xl")]: {
    width: "calc(100% + 26px)",
    marginLeft: "-13px",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "20px",
  },
});

export const PortfolioStackItem = styled(Box)({
  margin: "26px 26px 0 0",

  [theme.breakpoints.down("xl")]: {
    margin: "26px 13px 0",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "16px",
  },
});
// END OF PORTFOLIO STACK

// PORTFOLIO CHALLENGE
export const PortfolioChallenge = styled(Box)({
  marginTop: "120px",

  [theme.breakpoints.down("lg")]: {
    marginTop: "60px",
  },
});

export const PortfolioChallengeWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "0 -10px",

  [theme.breakpoints.down("xl")]: {
    flexWrap: "wrap",
    margin: 0,
  },
});
// END OF PORTFOLIO CHALLENGE

// PORTFOLIO SOLUTION
export const PortfolioSolution = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "120px",
  paddingBottom: "44px",
  borderBottom: `1px solid ${palette.amethystSmoke}`,

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    justifyContent: "normal",
    marginTop: "60px",
  },
});

export const PortfolioSolutionInfo = styled(Box)({
  maxWidth: "840px",
  width: "100%",

  "& .target": {
    fontSize: "48px",
    fontWeight: 600,
    lineHeight: "72px",
    letterSpacing: "0.06em",
    textTransform: "uppercase",
    background:
      "linear-gradient(to right, #000000 0%, #000 75%, #00000000 100%)",
    color: "transparent",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: "0px",
  },

  "& .copy": {
    fontSize: "48px",
    fontWeight: 600,
    lineHeight: "72px",
    letterSpacing: "0.06em",
    WebkitTextStrokeWidth: "1px",
    WebkitTextStrokeColor: palette.primary,
    color: palette.secondary,
    position: "relative",
    top: "-0px",
    left: "-0px",
    zIndex: -1,
    textTransform: "uppercase",
  },

  [theme.breakpoints.down("sm")]: {
    "& .target, & .copy": {
      fontSize: "34px",
      lineHeight: "42px",
    },
  },

  [theme.breakpoints.down("lg")]: {
    order: 1,
  },
});

export const PortfolioSolutionDescription = styled(Box)({
  marginTop: "26px",

  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.44px",
  },
});

export const PortfolioSolutionImage = styled(Box)({
  maxWidth: "239px",
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    order: 0,
    marginBottom: "20px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "87px",
  },
});
// END OF PORTFOLIO SOLUTION

// PORTFOLIO TECHNOLOGIES
export const PortfolioTechnologies = styled(Box)({
  marginTop: "44px",

  [theme.breakpoints.down("lg")]: {
    marginTop: "26px",
  },
});

export const PortfolioTechnologiesWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(380px, 1fr))",
  [theme.breakpoints.down("xl")]: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});
// END OF PORTFOLIO TECHNOLOGIES

// PORTFOLIO IMAGE
export const PortfolioImage = styled(Box)({
  position: "relative",
  right: "50%",
  left: "50%",
  width: "100vw",
  marginTop: "120px",
  marginRight: "-50vw",
  marginLeft: "-50vw",
  padding: "44px 0",

  "& img": {
    display: "block",
    maxWidth: "1820px",
    width: "100%",
    margin: "0 auto",
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "60px",
    padding: 0,
  },
});
// END OF PORTFOLIO IMAGE

// PORTFOLIO OUTCOME
export const PortfolioOutcome = styled(Box)({
  marginTop: "120px",

  [theme.breakpoints.down("lg")]: {
    marginTop: "60px",
  },
});

export const PortfolioOutcomeWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(380px, 1fr))",

  "&.oneSection": {
    gridTemplateColumns: "1fr",
    [theme.breakpoints.down("xl")]: {
      "& .single": {
        maxWidth: "unset",
      },
    },
  },
  "&.twoSections": {
    gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
  },
  [theme.breakpoints.down("xl")]: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});
// END OF PORTFOLIO OUTCOME

// ANIMATION IMAGE
export const AnimationImage = styled(Box)({
  display: "flex",
  justifyContent: "center",
  maxWidth: "495px",
  width: "100%",
  height: "310px",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});
// END OF ANIMATION IMAGE

// UNIVERSAL LIST ITEM BLOCK
export const ListItem = styled(Box)({
  width: "100%",
  marginTop: "26px",
  padding: "26px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",

  "& .listItemTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    paddingBottom: "26px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    textTransform: "uppercase",
    borderBottom: `1px solid ${palette.primary}`,
  },

  "& .listItemSubTitle": {
    marginBottom: "16px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    textTransform: "uppercase",
  },

  "&:hover": {
    color: palette.secondary,
    background: palette.primary,

    "& .listItemTitle": {
      borderBottom: `1px solid ${palette.secondary}`,

      "& svg path": {
        fill: palette.purpleBlue,
      },
    },
  },

  [theme.breakpoints.down("xl")]: {
    border: `1px solid ${palette.porcelain}`,
  },

  [theme.breakpoints.down("md")]: {
    color: palette.secondary,
    background: palette.primary,
    border: `1px solid ${palette.amethystSmoke}`,

    "& .listItemTitle": {
      borderBottom: `1px solid ${palette.secondary}`,

      "& svg path": {
        fill: palette.purpleBlue,
      },
    },
  },

  "@media (max-width: 375px)": {
    padding: 16,

    "& .listItemTitle": {
      marginBottom: "16px",
      paddingBottom: "16px",
    },
  },
});
// END OF UNIVERSAL LIST ITEM BLOCK

// CHALLENGES ITEM
export const ChallengesItem = styled(ListItem)({
  flexBasis: "auto",
  maxWidth: "580px",
  minWidth: "280px",
  margin: "44px 10px 0",

  [theme.breakpoints.down("xl")]: {
    flexBasis: "49%",
    maxWidth: "49%",
    marginRight: 0,
    marginLeft: 0,

    "&.single": {
      flexBasis: "100%",
      maxWidth: "100%",
    },
  },

  [theme.breakpoints.down("lg")]: {
    marginTop: "26px",
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
  },
});
// END OF CHALLENGES ITEM

// PORTFOLIO TECHNOLOGIES ITEM
export const PortfolioTechnologiesItem = styled(ListItem)({
  maxWidth: "380px",
  border: `1px solid ${palette.porcelain}`,

  [theme.breakpoints.down("xl")]: {
    maxWidth: "49%",
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
  },
});
// END OF PORTFOLIO TECHNOLOGIES ITEM

// PORTFOLIO OUTCOME ITEM
export const PortfolioOutcomeItem = styled(ListItem)({
  maxWidth: "380px",

  "&.single": {
    maxWidth: "100%",
    [theme.breakpoints.down("xl")]: {
      maxWidth: "49%",
    },
  },

  [theme.breakpoints.down("xl")]: {
    maxWidth: "49%",
  },

  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    height: "100%",
  },
});
// END OF PORTFOLIO OUTCOME ITEM
