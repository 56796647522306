import React from "react";
import { Slider } from "../slider";
import { PortfolioOutcomeItem } from "../../styles/portfolio";
import Cube from "../../assets/icons/Cube";
import Typography from "@mui/material/Typography";

const PortfolioOutcomeListSlider = ({ portfolioOutcomeList }) => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      portalIdNext="outcome-next-arrow"
      portalIdPrev="outcome-prev-arrow"
      width="100%"
      margin="0px"
      wrapperProps={{
        marginBottom: "68px",
      }}
    >
      {portfolioOutcomeList?.theOutcomeItems?.map((item) => {
        return (
          <PortfolioOutcomeItem key={item.id}>
            <div className="listItemTitle">
              {item.title}
              <Cube />
            </div>
            {item.subTitle && (
              <Typography variant="h4" className="listItemSubTitle">
                {item.subTitle}
              </Typography>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          </PortfolioOutcomeItem>
        );
      })}
    </Slider>
  );
};

export default PortfolioOutcomeListSlider;
