import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import palette from "../../theme/palette";
import theme from "../../theme";

export const StyledPortfolioCategoriesList = styled(Box)({
  alignSelf: "self-start",
  width: "240px",
  padding: "12px",
  border: `1px solid ${palette.primary}`,
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    alignSelf: "normal",
    width: "100%",
    padding: 0,
    border: "none",
  },
});

export const PortfolioCategoriesItem = styled(Box)({
  margin: "6px 0 0",
  padding: 0,

  "&:last-child": {
    marginTop: 0,
  },

  "&.mainWrapper": {
    order: -1,
  },

  [theme.breakpoints.down("lg")]: {
    margin: "8px 16px 0 0",

    "&:last-child": {
      margin: "8px 0 0",
    },
  },
});

export const PortfolioCategoriesItemInner = styled(Box)({
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "26px",
  letterSpacing: "0.15px",
  textDecoration: "underline",

  "&.main": {
    display: "flex",
    color: `${palette.purpleBlue} !important`,
    fontWeight: "600",
  },

  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },
});

export const PortfolioCategoriesLinkBorder = styled(Box)({
  width: "1px",
  marginRight: "16px",
  background: palette.purpleBlue,

  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
});
